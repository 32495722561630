import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from "react-ga4";
import history from "./services/history";

import { icons } from './assets/icons'

React.icons = icons
ReactGA.initialize("G-HX9NP10CZ9", {
    debug: true,
    gaOptions: {
      allowLinker: true,
    },
  });
  ReactGA.ga('require', 'linker');
  ReactGA.ga('linker:autoLink', ['app.ares.thefitlab.io']);
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

ReactDOM.render(<App/>, document.getElementById('root'));

