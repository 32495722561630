export const getUsername = () => {
  return sessionStorage.getItem("user") || null;
};

export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  const userToken = sessionStorage.getItem("token");
  if (userStr) return { user: userStr, token: userToken };
  else return null;
};

export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", user);
};

export function authHeader() {
  // return authorization header with jwt token
  const currentUser = getUser();
  if (currentUser && currentUser.token) {
    return { "x-access-token": `${currentUser.token}` };
  } else {
    return {};
  }
}
